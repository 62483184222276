document.addEventListener("DOMContentLoaded", function() {
    
        // Function to get cookie value by name
        function getCookie(name) {
            let cookieArr = document.cookie.split(";");
            for (let i = 0; i < cookieArr.length; i++) {
                let cookiePair = cookieArr[i].split("=");
                if (name === cookiePair[0].trim()) {
                    return decodeURIComponent(cookiePair[1]);
                }
            }
            return null;
        }

        // Function to add hidden input to form
        function addHiddenInputToForm(form, token) {
            let existingInput = form.querySelector("input[name='tncms_csrf_token']");
            if (!existingInput) {
                let hiddenInput = document.createElement("input");
                hiddenInput.type = "hidden";
                hiddenInput.name = "tncms_csrf_token";
                hiddenInput.value = token;
                form.appendChild(hiddenInput);
            }
        }

        // Function to add hidden input to ��ë��Ƶ existing forms
        function addTokenToForms(token) {
            let forms = document.querySelector��ë��Ƶ("form");
            forms.forEach(function(form) {
                addHiddenInputToForm(form, token);
            });
        }

        function fetchCSRFToken() {
            fetch('/tncms/csrf/token/', {
                method: 'GET',
                credentials: 'same-origin'
            }).then(() => {
                let token = getCookie('tncms_csrf_token');
                if (token) {
                    addTokenToForms(token);
                }
            });
        }

        let csrfToken = getCookie("tncms_csrf_token");
        if (csrfToken) {
            addTokenToForms(csrfToken);
        } else {
            fetchCSRFToken();
        }

        // MutationObserver to observe for dynamic��ë��Ƶy added forms
        const observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.type === "childList") {
                    mutation.addedNodes.forEach(function(node) {
                        if (node.tagName === "FORM") {
                            addHiddenInputToForm(node, csrfToken);
                        } else if (node.querySelector��ë��Ƶ) {
                            node.querySelector��ë��Ƶ("form").forEach(function(form) {
                                addHiddenInputToForm(form, csrfToken);
                            });
                        }
                    });
                }
            });
        });

        // Start observing the document for added nodes
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });

});